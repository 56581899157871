<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Konten Perpustakaan Baru</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-form :form="form" @submit="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <a-form-item label="Judul" class="col-md-12">
                      <a-input
                          placeholder="Judul konten"
                          v-decorator="[
                          'title',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Judul minimal 3 karakter!'
                              },
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="Isi konten" class="col-md-12">
                      <quill-editor
                          theme="snow"
                          class="editor"
                          ref="myTextEditor"
                          :options="editorOption"
                          @change="onEditorChange"
                          v-model="content"
                          :value="content"
                      />
                    </a-form-item>
                    <a-form-item label="Video" class="col-md-12">
                      <a-input
                          placeholder="Link video"
                          v-decorator="[
                          'video',
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="Kategori" class="col-md-6">
                      <a-select
                          placeholder="Pilih kategori"
                          show-search
                          :filter-option="filterOption"
                          :not-found-content="null"
                          style="width: 100%"
                          v-decorator="[
                          'category_id',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              }
                            ]
                          }
                        ]"
                      >
                        <a-select-option
                            v-for="category in categories"
                            :key="category.id"
                        >{{ category.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="Privasi" class="col-md-6">
                      <a-select
                          placeholder="Pilih privasi"
                          mode="multiple"
                          style="width: 100%"
                          :default-active-first-option="false"
                          :show-arrow="true"
                          :filter-option="false"
                          :not-found-content="null"
                          @search="handleSearchGroup"
                          v-decorator="[
                          'groups',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              }
                            ]
                          }
                        ]"
                      >
                        <a-select-option
                            v-for="group in groups"
                            :key="group.name"
                        >
                          {{ group.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="Upload Foto" class="col-md-12">
                      <a-upload
                          name="image"
                          listType="picture-card"
                          class="avatar-uploader"
                          :file-list="fileList"
                          :multiple="true"
                          :beforeUpload="beforeUpload"
                          @change="onFileChange"
                      >
                        <a-icon :type="loading ? 'loading' : 'plus'"/>
                        <div class="ant-upload-text">Pilih Foto</div>
                      </a-upload>
                    </a-form-item>
                    <a-form-item label="Attachment" class="col-md-12">
                      <a-upload
                          name="attachment"
                          :showUploadList="false"
                          :beforeUpload="beforeUploadAttachment"
                          @change="onFileChangeAttachment"
                          accept=".pdf"
                          v-decorator="[
                        'attachment',
                      ]"
                      >
                        <a-button>
                          <a-icon type="upload"/>
                          Attachment
                        </a-button>
                      </a-upload>
                      <p>{{ attachment_filename }}</p>
                    </a-form-item>
                    <a-form-item label="On-Boarding" class="col-md-12">
                      <a-switch v-bind:checked="onBoarding" @change="onChangeOnBoarding" />
                    </a-form-item>
                  </div>
                </div>

                <div class="col-md-12">
                  <button type="button" class="btn btn-primary btn-with-addon" v-on:click="onClickSave">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-save"/>
                    </span>
                    Simpan
                  </button>
                  <button type="button" class="btn btn-primary btn-with-addon ml-2" v-on:click="onClickPublish">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                    Publikasi
                  </button>
                  <button type="button" class="btn btn-primary btn-with-addon ml-2" v-on:click="onClickSetSchedule">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-calendar"/>
                    </span>
                    Atur Jadwal
                  </button>
                  <LibraryContentScheduleVue 
                    :visible="visibleSchedule"
                    @cancel="handleCancelSchedule"
                    @saved="handleSuccessSchedule"
                  />
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import {getListCategoryLibraryContent} from "@/api/category.api";
import {getListGroup} from "@/api/group.api";
import {createLibrary} from "@/api/libraryContent.api";
import debounce from "lodash/debounce";
import hljs from 'highlight.js';
import moment from "moment";
import LibraryContentScheduleVue from "../LibraryContentSchedule.vue";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  components: {
    LibraryContentScheduleVue
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'font': []}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        },
      },
      form: this.$form.createForm(this),
      groups: [],
      categories: [],
      imageUrl: '',
      thumbnail: null,
      loading: false,
      attachment: null,
      attachment_filename: "",
      content: ``,
      fileList: [],
      schedule: null,
      visibleSchedule: false,
      onBoarding: false
    }
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value
    }
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    onEditorChange: debounce(function (value) {
      this.content = value.html
    }, 466),
    onChangeOnBoarding(checked) {
      this.onBoarding = checked
    },
    onClickSave(e) {
      this.handleSubmit(e);
    },
    onClickPublish(e) {
      this.handleSubmit(e, true);
    },
    handleCancelSchedule() {
      this.visibleSchedule = false
    },
    handleSuccessSchedule(e, publishDate) {
      this.schedule = publishDate;
      this.visibleSchedule = false
      this.handleSubmit(e);
    },
    onClickSetSchedule() {
      this.visibleSchedule = true;
    },
    handleSubmit(e, publish = false) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        const group = JSON.stringify(value.groups).toString();
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.set("title", value.title);
          formData.set("body", this.content);
          if(value.video != undefined){
            formData.set("video", value.video);
          }
          formData.set("category_id", value.category_id);
          formData.set("groups", group.substring(1, group.length - 1).replace(/["']/g, ""));
          if (this.fileList.length > 0) {
            for(const file of this.fileList) {
              formData.append('images[]', file);
            }
          }
          if (this.attachment != null) {
            formData.set("attachment", this.attachment);
          }
          formData.set("onboarding", this.onBoarding ? "1" : "0")
          if(this.schedule != null) {
            formData.set("publish_schedule", this.schedule)
          }
          if(publish) {
            const curDate = moment().format('YYYY-MM-DD HH:mm:ss')
            formData.set("published_at", curDate)
          }
          if(!this.content){
            this.$notification["warning"]({
              message: "Invalid",
              description: "Isi konten tidak boleh kosong!"
            });
            this.$nprogress.done();
          }else {
            this.submitData(formData);
          }
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    },
    beforeUpload() {
      return false;
    },
    onFileChange(info) {
      if (info.file.status && info.file.status == 'removed') {
        this.fileList = this.fileList.filter(it => it.uid != info.file.uid);
      } else if(this.checkImageUpload(info.file)) {
        if(this.fileList.length == 6) {
          this.$notification["warning"]({
            message: "Invalid",
            description: "Jumlah gambar tidak boleh lebih dari 6"
          });
          return false;
        }
        getBase64(info.file, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
          const image = info.file;
          image.url = imageUrl;
          this.fileList.push(image);
        });
      }
    },
    beforeUploadAttachment() {
      return false;
    },
    onFileChangeAttachment(info) {
      const isLt2M = info.file.size / 1024 <= 20480;
      if (!isLt2M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
      } else {
        this.attachment = info.file;
        this.attachment_filename = info.file.name;
      }

    },
    checkImageUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
      var validFormat = true;
      if (!isJpgOrPng) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Format Foto tidak Valid"
        });
        validFormat = false;
      }
      const isLt2M = file.size / 1024 <= 20480;
      if (!isLt2M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
        validFormat = false;
      }
      return validFormat;
    },
    async fetchCategories() {
      try {
        let params = {
          limit: 200
        };
        let result = await getListCategoryLibraryContent(params);
        this.categories = result.data.results;
      } catch (error) {/**/
      }
    },
    async handleSearchGroup(value) {
      if (value.length >= 1) {
        try {
          let params = {
            limit: 200,
            search: value
          };
          let result = await getListGroup(params);
          this.groups = result.data.results;
        } catch (error) {/**/
        }
      }
    },
    async submitData(params) {
      try {
        let response = await createLibrary(params);
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil menambahkan konten perpustakaan baru"
          });
          await this.$router.push("/library-content");
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
  }
}
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }
}
</style>

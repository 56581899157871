<template>
  <a-modal
    title="Atur Jadwal Publikasi"
    :visible="visible"
    @ok="handleOkInvite"
    @cancel="handleCancelInvite">
    <a-form :form="form">
      <a-form-item label='Tanggal Publikasi'>
        <a-date-picker
          :show-time="{ format: 'HH:mm' }"
          format="DD-MM-YYYY HH:mm"
          :disabled-date="disabledDate"
          v-decorator="rules.schedule"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import Model from "./LibraryContentModel";
import moment from 'moment';

export default {
  props: {
    visible: Boolean
  },
  data: () => {
    return {
      rules: Model.rules.schedule,
      form: null
    };
  },
  mounted() {
    this.form = this.$form.createForm(this)
  },
  methods: {
    handleCancelInvite() {
      this.$emit('cancel');
    },

    handleOkInvite(e) {
      this.form.validateFields().then((res) => {
        this.$emit('saved', e, res.schedule.format('YYYY-MM-DD HH:mm:ss'));
      })
    },

    disabledDate(current) {
      return current < moment().add(-1, 'd')
    }
  }
}

</script>
  
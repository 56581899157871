var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Judul"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'title',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini tidak boleh kosong'
                            },
                            {
                              min: 3,
                              message: 'Judul minimal 3 karakter!'
                            } ]
                        }
                      ]),expression:"[\n                        'title',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini tidak boleh kosong'\n                            },\n                            {\n                              min: 3,\n                              message: 'Judul minimal 3 karakter!'\n                            },\n                          ]\n                        }\n                      ]"}],attrs:{"placeholder":"Judul konten"}})],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Isi konten"}},[_c('quill-editor',{ref:"myTextEditor",staticClass:"editor",attrs:{"theme":"snow","options":_vm.editorOption,"value":_vm.content},on:{"change":_vm.onEditorChange},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Video"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'video' ]),expression:"[\n                        'video',\n                      ]"}],attrs:{"placeholder":"Link video"}})],1),_c('a-form-item',{staticClass:"col-md-6",attrs:{"label":"Kategori"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'category_id',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini tidak boleh kosong'
                            }
                          ]
                        }
                      ]),expression:"[\n                        'category_id',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini tidak boleh kosong'\n                            }\n                          ]\n                        }\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Pilih kategori","show-search":"","filter-option":_vm.filterOption,"not-found-content":null}},_vm._l((_vm.categories),function(category){return _c('a-select-option',{key:category.id},[_vm._v(_vm._s(category.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"col-md-6",attrs:{"label":"Privasi"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'groups',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini tidak boleh kosong'
                            }
                          ]
                        }
                      ]),expression:"[\n                        'groups',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini tidak boleh kosong'\n                            }\n                          ]\n                        }\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Pilih privasi","mode":"multiple","default-active-first-option":false,"show-arrow":true,"filter-option":false,"not-found-content":null},on:{"search":_vm.handleSearchGroup}},_vm._l((_vm.groups),function(group){return _c('a-select-option',{key:group.name},[_vm._v(" "+_vm._s(group.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Upload Foto"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"image","listType":"picture-card","file-list":_vm.fileList,"multiple":true,"beforeUpload":_vm.beforeUpload},on:{"change":_vm.onFileChange}},[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Pilih Foto")])],1)],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"Attachment"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'attachment' ]),expression:"[\n                      'attachment',\n                    ]"}],attrs:{"name":"attachment","showUploadList":false,"beforeUpload":_vm.beforeUploadAttachment,"accept":".pdf"},on:{"change":_vm.onFileChangeAttachment}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Attachment ")],1)],1),_c('p',[_vm._v(_vm._s(_vm.attachment_filename))])],1),_c('a-form-item',{staticClass:"col-md-12",attrs:{"label":"On-Boarding"}},[_c('a-switch',{attrs:{"checked":_vm.onBoarding},on:{"change":_vm.onChangeOnBoarding}})],1)],1)]),_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-primary btn-with-addon",attrs:{"type":"button"},on:{"click":_vm.onClickSave}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-save"})]),_vm._v(" Simpan ")]),_c('button',{staticClass:"btn btn-primary btn-with-addon ml-2",attrs:{"type":"button"},on:{"click":_vm.onClickPublish}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-send"})]),_vm._v(" Publikasi ")]),_c('button',{staticClass:"btn btn-primary btn-with-addon ml-2",attrs:{"type":"button"},on:{"click":_vm.onClickSetSchedule}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-calendar"})]),_vm._v(" Atur Jadwal ")]),_c('LibraryContentScheduleVue',{attrs:{"visible":_vm.visibleSchedule},on:{"cancel":_vm.handleCancelSchedule,"saved":_vm.handleSuccessSchedule}})],1)])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"air__utils__heading"},[_c('h5',[_c('span',{staticClass:"mr-3"},[_vm._v("Konten Perpustakaan Baru")])])])}]

export { render, staticRenderFns }